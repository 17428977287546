<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="600"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="标题"
          :validate="validateInfos.title"
          v-model:value="modelRef.title"
        />

        <e-input title="电话" :valueCol="6" v-model:value="modelRef.phone" />

        <e-input-upload title="竖版Logo" v-model:value="modelRef.logo" />
        <e-input-upload title="横版Logo" v-model:value="modelRef.logoAcross" />

        <e-input title="经度" :valueCol="7" v-model:value="modelRef.lng" />
        <e-input title="纬度" :valueCol="7" v-model:value="modelRef.lat" />

        <e-input title="详细地址" v-model:value="modelRef.address" />

        <e-textarea title="描述" v-model:value="modelRef.description" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { StoreClass } from '@/apis/store'

const useForm = Form.useForm
const api = new StoreClass()
export default defineComponent({
  components: {},
  mixins: [form],
  emits: ['ok'],
  setup () {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose
    }
  },

  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.title = formData.title
        this.modelRef.logo = formData.logo
        this.modelRef.logoAcross = formData.logoAcross
        this.modelRef.lng = formData.lng
        this.modelRef.lat = formData.lat
        this.modelRef.phone = formData.phone
        this.modelRef.address = formData.address
        this.modelRef.description = formData.description
        this.modelRef.id = formData.id

        this.title = '编辑：' + formData.title
        this.id = formData.id
      } else {
        this.modelRef = reactive(api.modelRef)

        this.title = '添加酒店'
        this.id = 0
      }

      this.visible = true
    },

    onSubmitFun (e) {
      const data = toRaw(e)

      if (this.id) {
        api.update(data).then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$emit('ok', data)
          this.onClose()
        })
      } else {
        api.create(data).then(() => {
          this.loading = false
          this.$message.success('添加成功')
          this.$emit('ok', data)
          this.onClose()
        })
      }
    }
  }
})
</script>

<style lang="less" scoped>
.label-title {
  position: relative;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  text-align: right;

  &:after {
    content: ":";
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}
</style>
