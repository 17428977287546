<template>
  <e-modal
    :visible="show"
    title="查看图片"
    :width="800"
    :footer="false"
    :onlyBody="true"
    @close="onClose"
    @ok="onOk"
  >
    <div class="image-box" v-if="image">
      <DragRoom @resize="onResizeFun">
        <img :src="image" class="img" alt="" :style="style" />
      </DragRoom>
    </div>
  </e-modal>
</template>
<script>
import { defineComponent, ref } from 'vue'
import DragRoom from '@/components/DragRoom'

export default defineComponent({
  components: {
    DragRoom
  },
  setup () {
    const show = ref(false)
    const image = ref('')
    const style = ref({})

    const onClose = () => {
      image.value = ''
      style.value = {}
      show.value = false
    }

    const onOk = e => {
      console.log(e)
    }

    return {
      show,
      image,
      style,
      onClose,
      onOk
    }
  },

  data () {
    return {
      width: 0,
      height: 0
    }
  },

  methods: {
    open (image) {
      this.image = image
      this.show = true
    },
    onResizeFun (e) {
      this.style = {
        width: e.w + 'px',
        height: e.h + 'px',
        left: e.l + 'px',
        top: e.t + 'px'
      }
    }
  }
})
</script>

<style lang="less" scoped>
.image-box {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    display: block;
  }
}
</style>
