<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="5">
              <e-input
                title="名称"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.Title"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>

              <a-button
                type="primary"
                style="margin-left: 10px"
                @click="addForm(() => $refs.eForm.open(null))"
              >
                添加酒店
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'logoAcross'">
              <div class="logo" v-if="record.logoAcross">
                <img :src="record.logoAcross" :alt="record.title" @click="$refs.eViewImage.open(record.logoAcross)" />
              </div>
              <span v-else>暂未设置</span>
            </template>

            <template v-if="column.key === 'logo'">
              <div class="logo" v-if="record.logo">
                <img :src="record.logo" :alt="record.title" @click="$refs.eViewImage.open(record.logo)" />
              </div>
              <span v-else>暂未设置</span>
            </template>

            <template v-if="column.key === 'createTime'">
              {{ $filters.formatDate(record.createTime) }}
            </template>

            <template v-if="column.key === 'updateTime'">
              {{ $filters.formatDate(record.updateTime) }}
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>编辑</template>
                <a @click.stop="editForm(() => $refs.eForm.open(record))">
                  <EditOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EForm ref="eForm" @ok="getDataList(1)" />
    <EViewImage ref="eViewImage" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { StoreClass } from '@/apis/store'
import page from '@/mixins/page'
import EForm from '@/components/forms/store/Form'
import EViewImage from '@/components/ViewImage'

const api = new StoreClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    EForm,
    EViewImage
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '酒店名称',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: '横版Logo',
        dataIndex: 'logoAcross',
        key: 'logoAcross',
        width: 150
      },
      {
        title: '竖版Logo',
        dataIndex: 'logo',
        key: 'logo',
        width: 150
      },
      {
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 200
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
        key: 'updateTime',
        width: 200
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 100
      }
    ]
    return {
      columns,
      loading,
      height,
      width
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.title}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped>
.info {
  display: flex;
  align-items: center;
  .thumb {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.logo {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
